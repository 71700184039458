import request from '../utils/request';
//检验身份证--true
export function toIdCardCheck(data) {
  return request({
    url: '/web/toIdCardCheck.html',
    method: 'post',
    data
  });
}

//检验手机号--true
export function toBindMobileCheck(data) {
  return request({
    url: '/web/toBindMobileCheck.html',
    method: 'post',
    data
  });
}

//发信短信--true
export function sendDx(data) {
  return request({
    url: '/web/sendDx.html',
    method: 'post',
    data
  });
}

//注册--true
export function doRegister(data) {
  return request({
    url: '/web/doRegister.html',
    method: 'post',
    data
  });
}

//获取安徽地区所有信息
export function getAllAreaList(data) {
  return request({
    url: '/web/getAllAreaList.html',
    method: 'post',
    data
  });
}

//获取图片验证
export function captchaImage() {
  return request({
    url: '/captchaImage.html',
    method: 'get',
  });
}

//查证书
export function toCertVerify(data) {
  return request({
    url: '/web/toCertVerify.html',
    method: 'post',
    data
  });
}


