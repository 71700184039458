<template>
  <div style="position: absolute; top: 0; width: 100%; height: 100%; background: #f0f2f5">
    <pcHeaders></pcHeaders>

    <div class="cen">
      <div>
        <div class="w">
          <div class="wBar">
            <div>证书查询</div>
          </div>

          <div class="flex"
               style="margin-top: 20px">
            <div style="font-size: 14px; line-height: 28px; margin-right: 10px">查询方式 :</div>
            <el-select size="mini"
                       placeholder="请选择"
                       v-model="searchType"
                       @change="handleSear">
              <el-option v-for="item in options"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value"> </el-option>
            </el-select>
          </div>

          <div class="flex"
               v-if="searchType == 'sfxx'"
               style="margin-top: 20px; font-size: 14px">
            <div style="line-height: 28px">姓名 :</div>
            <div style="margin-left: 10px"><el-input size="mini"
                        placeholder="请输入姓名"
                        v-model="queryCert.realName"></el-input></div>
            <div style="line-height: 28px; margin-left: 10px">身份证 :</div>
            <div style="margin-left: 10px"><el-input size="mini"
                        placeholder="请输入身份证"
                        v-model="queryCert.cardNo"></el-input></div>
            <div style="display: flex">
              <el-input style="margin-left: 10px"
                        size="mini"
                        placeholder="请输入验证码"
                        v-model="queryCert.checkCode"></el-input>

              <img @click="getImgCode"
                   style="height: 28px; margin-left: 10px; cursor: pointer"
                   :src="codeImg"
                   alt="" />
            </div>
            <div></div>
            <div>
              <el-button type="primary"
                         icon="el-icon-search
"
                         style="margin-left: 10px"
                         size="mini"
                         @click="subMit">搜索</el-button>
            </div>
          </div>

          <div class="flex"
               v-else
               style="margin-top: 20px; font-size: 14px">
            <div style="line-height: 28px">证书编号 :</div>
            <div><el-input style="margin-left: 10px;width:220px;"
                        v-model="queryCert.certNo"
                        size="mini"
                        placeholder="请输入证书编号"></el-input></div>
            <div style="margin-left: 10px; display: flex">
              <el-input style="margin-left: 10px"
                        size="mini"
                        placeholder="请输入验证码"
                        v-model="queryCert.checkCode"></el-input>
              <img @click="getImgCode"
                   style="height: 28px; margin-left: 10px; cursor: pointer"
                   :src="codeImg"
                   alt="" />
            </div>

            <div>
              <el-button style="margin-left: 10px"
                         type="primary"
                         icon="el-icon-search"
                         @click="subMit"
                         size="mini">搜索</el-button>
            </div>
          </div>
        </div>
        <el-table v-if="certList.length"
                  :data="certList"
                  style="width: 100%;margin-top: 5px;margin-bottom: 50px;">
          <el-table-column prop="certNo"
                           label="证书编号"
                           width="180"
                           align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.lx == '公需课'">
                -
              </span>
              <span v-else>
                {{ scope.row.certNo }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="certName"
                           label="证书名称"
                           width="180"
                           align="center">
          </el-table-column>
          <el-table-column prop="realname"
                           label="姓名"
                           align="center">
          </el-table-column>
          <el-table-column prop="cardno"
                           label="身份证"
                           align="center">
          </el-table-column>
          <el-table-column prop="year"
                           label="年度"
                           align="center">
          </el-table-column>
          <el-table-column prop="certType"
                           label="类型"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.lx}}
              <!-- {{ scope.row.certType == '0701' ? '公需课' : scope.row.certType == '0702' ? '专业' : scope.row.certType == '0703' ? '转岗' : '' }} -->
            </template>
          </el-table-column>
          <el-table-column prop="credit"
                           label="完成学时"
                           align="center">
          </el-table-column>
          <el-table-column prop="address"
                           label="操作"
                           align="center">
            <template slot-scope="scope">
              <span style="cursor: pointer;color: burlywood;"
                    @click="lookCert(scope.row)">查看证书</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <pcBottom></pcBottom>
    <flotRight/>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import pcHeaders from '../component/head.vue';
import pcBottom from '../component/bottom.vue';
import flotRight from "@/views/component/flotRight.vue";
import { captchaImage, toCertVerify } from '@/api/regist.js';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    pcHeaders,
    pcBottom,
    flotRight
  },
  data() {
    // 这里存放数据
    return {
      options: [
        {
          value: 'sfxx',
          label: '身份信息'
        },
        {
          value: 'zsbh',
          label: '证书编号'
        }
      ],
      searchType: 'sfxx',
      queryCert: {},
      codeImg: '',
      certList: [], //证书列表
      cerTinfo: {} //公需课的判断
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    //查看证书
    lookCert(row) {
      console.log(row);
      if (
        (row.classNo !== null) &
        (row.classNo == this.cerTinfo.TM_SPECIAL_CLASSNO ||
          row.classNo == this.cerTinfo.TM_SPECIAL_CLASSNO_2022 ||
          row.classNo == this.cerTinfo.TM_SPECIAL_CLASSNO_2023)
      ) {
        this.$confirm('从2021年开始，公需课证书统一在安徽省专业技术人员继续教育管理平台查询打印', '提示', {
          confirmButtonText: '前往查看',
          cancelButtonText: '退出',
          type: 'warning'
        })
          .then(() => {
            window.open(this.cerTinfo.tmUrl, '_blank');
          })
          .catch(() => {});
        return false;
      }
      this.$router.push({
        path: '/cert',
        query: {
          id: row.id
        }
      });
    },
    handleSear() {
      this.getImgCode();

      this.queryCert = {};
    },
    //获取图片验证码
    getImgCode() {
      captchaImage().then(res => {
        this.codeImg = 'data:image/png;base64,' + res.data.img;
        this.queryCert.logo = res.data.uuid;
      });
    },
    //搜索提交
    subMit() {
      if (this.searchType == 'sfxx') {
        if (this.queryCert.realName == '' || this.queryCert.realName == undefined) {
          this.$message('姓名不可为空！');
          return false;
        }
        if (this.queryCert.cardNo == '' || this.queryCert.cardNo == undefined) {
          this.$message('身份证号不可为空！');
          return false;
        }

        if (this.queryCert.checkCode == '' || this.queryCert.checkCode == undefined) {
          this.$message('图片验证码不可为空！');
          return false;
        }
      } else {
        if (this.queryCert.certNo == '' || this.queryCert.certNo == undefined) {
          this.$message('证书编号不可为空！');
          return false;
        }
        if (this.queryCert.checkCode == '' || this.queryCert.checkCode == undefined) {
          this.$message('图片验证码不可为空！');
          return false;
        }
      }
      this.queryCert.searchType = this.searchType;
      toCertVerify(this.queryCert).then(res => {
        console.log(res.data.data.code);
        if (res.data.data.code == '1') {
          this.cerTinfo = res.data.data;
          this.certList = res.data.data.certList;
          if (this.certList.length == 0) {
            this.$message('暂未查询到证书！');
            return false;
          }
        } else {
          this.$message(res.data.data.msg);
          this.getImgCode();
        }
      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getImgCode();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.flex {
  display: flex;
}
.cen {
  font-family: Regular;
  background: #f5f5f5;
  padding-top: 50px;
  display: flex;
  justify-content: center;
  min-height: 70vh;
  font-size: 16px;
  .w {
    width: 1024px;
    height: 236px;
    background: #fff;
    padding: 35px 30px 30px 60px;
    .wBar {
      display: flex;
      border-bottom: 3px solid #d6d6d6;
      div {
        color: #474747;
        font-size: 18px;
        width: 105px;
        text-align: center;
        cursor: pointer;
        padding-bottom: 10px;
      }
    }
  }
}
</style>
